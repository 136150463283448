import _ from 'lodash';
import BaseService from '~@core/service/BaseService';
import {Collection, SuccessResponse} from '~@core/type/ApiType';
import {AnyObject, Keyable} from '~@core/type/Common';

class CRUDService<T> extends BaseService {

  protected commandPath: string;

  protected queryPath: string;

  protected fullLoad: boolean

  constructor(commandPath: string, queryPath: string) {
    super();
    this.commandPath = commandPath;
    this.queryPath = queryPath;
    this.fullLoad = false;
  }

  protected buildWith(payload) {
    if (!this.fullLoad) {
      return payload;
    }
    const data = payload === undefined || payload === null ? {} : payload;
    if (_.isObject(data)) {
      // @ts-ignore safe
      if (data?.with === undefined) {
        // @ts-ignore safe
        data.with = ['*'];
      }
    }
    return data;
  }

  protected buildReadPayload(payload) {
    return this.buildWith(payload);
  }

  protected commandUrl(path: string) {
    return `${this.commandPath}/${path}`;
  }

  protected queryUrl(path: string) {
    return `${this.queryPath}/${path}`;
  }

  init() {
    this.http.addHeaders({
      Authorization: this.getAccessToken,
      'Request-Scope': () => {
        const scope = window.location.pathname.split('/')?.[2];
        return scope === 'admin' ? 'admin' : ''
      }
    });
  }

  async search(payload?) {
    const ret = await this.http.callPost(`${this.queryPath}/search`, this.buildReadPayload(payload));
    return ret as SuccessResponse<Collection<T>>;
  }

  async list(payload = {}) {
    const [data, ...others] = await this.search(payload);
    const items = (_.get(data, 'data') || []) as T[];
    return [items, ...others] as SuccessResponse<T[]>;
  }

  // all(payload) {
  //   return this.http.callPost(this.path, {perPage: 10000, ...payload});
  // }

  // count(payload) {
  //   return this.http.callGet(`${this.path}:count`, payload);
  // }
  async changePassword(payload: AnyObject = null) {
    const ret = await this.http.callPost(`${this.commandPath}/change-password`, {...payload});
    return ret as SuccessResponse<T>;
  }

  async get(id, payload: AnyObject = null) {
    const pl = this.buildReadPayload(payload);
    const ret = await this.http.callPost(`${this.queryPath}/find`, {id, ...pl});
    return ret as SuccessResponse<T>;
  }

  // getDetails(id, payload) {
  //   const tmp = _.assign({with: ['*']}, payload);
  //   return this.get(id, tmp);
  // }

  async create(data) {
    const ret = await this.http.callPost(`${this.commandPath}/store`, {...data});
    return ret as SuccessResponse<T>;
  }

  async update(id, data) {
    const ret = await this.http.callPost(`${this.commandPath}/update`, {id, ...data});
    return ret as SuccessResponse<T>;
  }

  async delete(id) {
    const ret = await this.http.callPost(`${this.commandPath}/destroy`, {id});
    return ret as SuccessResponse<T>;
  }

  save(entity) {
    if (entity.id) {
      return this.update(entity.id, entity);
    }
    return this.create(entity);

  }

  async massActions(data: {
    command: string,
    ids: string[] | number[],
    values: Keyable
  }) {
    const ret = await this.http.callPost(`${this.commandPath}/mass-actions`, {...data});
    return ret as SuccessResponse<T>;
  }

  // delete(id, payload) {
  //   return this.http.callDelete(`${this.path}/${id}`, payload);
  // }
}

export default CRUDService;